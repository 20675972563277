import React from "react";

import { Heading, VStack } from "@chakra-ui/react";

const OurMission = () => (
  <VStack
    as="section"
    id="our-mission"
    paddingY={20}
    width={["full", "full", "75%"]}
    marginX="auto"
    paddingX={[5, 5, 0]}
    spacing={10}
    alignItems="start"
    textAlign="center"
    h="100vh"
  >
    <Heading
      alignSelf="center"
      fontFamily="Geomanist"
      fontSize={["5xl", "7xl"]}
    >
      Did you find yourself in those questions?
    </Heading>

    <Heading
      alignSelf="center"
      textColor="#5dba77"
      fontFamily="Geomanist"
      fontSize={["5xl", "7xl"]}
    >
      We got you covered
    </Heading>
  </VStack>
);

export default OurMission;
