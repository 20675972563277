import React from "react";

import { Box, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const Features = () => (
  <Grid
    templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
    gap={50}
    mx="auto"
    width="90%"
  >
    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/for_you_page.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          For you page
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          Why would you need to think about what you want to see or need to
          search for specific places when you can get inspired thanks to your
          own just-for-you page?
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/filters.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          Filter your way to places
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          You choose what you want to explore next. Thanks to our filters you
          can select what is currently relevant for you.
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/recommendations.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          See nearby places
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          Don't you want to travel long distances? With our nearby
          recommendations, you have access to places, that will save you time
          walking. You can spend it then on enjoying experiences in those
          places.
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/route.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          Optimized trip routes
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          We are calculating routes, that will get you from each destination to
          the next one in the shortest way possible. We made it with road trips
          in mind.
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/trip_privacy.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          Trips privacy
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          Share your trips with other travelers or you can keep it private just
          to yourself.
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/trip_plan.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          Trip plan
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          Never forget places you want to visit during your trip. This trip plan
          gives you the possibility to see routes between places in optimized
          way
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/place_detail.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          All information needed
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          We created a user interface in a way, that every user that adds for
          other all the information needed. You will know everything you need
          before you go on a road.
        </Text>
      </VStack>
    </GridItem>

    <GridItem position="relative">
      <VStack textAlign="center">
        <Box rounded="2xl" overflow="hidden">
          <StaticImage
            src="../images/travelers.png"
            alt="Weeletio filters screenshot to find the perfect trip"
            quality={100}
            width={300}
          />
        </Box>

        <Heading fontFamily="Geomanist" as="h3" fontSize="xl">
          Connect with other travelers
        </Heading>
        <Text fontFamily="Geomanist" fontWeight="medium">
          Connect with other travelers, and see where they are planning to go.
          With this, you can find new friends for life.
        </Text>
      </VStack>
    </GridItem>
  </Grid>
);

export default Features;
