import React from "react";

import { Box, Heading, HStack, VStack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const Banner = ({ isLargerThanMd }: { isLargerThanMd: boolean }) => (
  <>
    {isLargerThanMd ? (
      <Box height="100vh" position="relative">
        <HStack mx="auto" width="90%">
          <Box width="75%">
            <Heading fontSize="5xl" fontFamily="Geomanist">
              Why wait for 10 years to gain knowledge, when you can have it in 1
              minute?
            </Heading>
          </Box>

          <Box>
            <StaticImage
              src="../images/cover_img.png"
              alt="Banner"
              placeholder="blurred"
              layout="constrained"
              width={650}
              quality={95}
            />
          </Box>
        </HStack>
      </Box>
    ) : (
      <Box height="100vh" position="relative">
        <VStack mx="auto" width="90%" zIndex={10}>
          <Box>
            <Heading fontSize="4xl" fontFamily="Geomanist">
              Why wait for 10 years to gain knowledge, when you can have it in 1
              minute?
            </Heading>
          </Box>

          <Box>
            <StaticImage
              src="../images/cover_img.png"
              alt="Banner"
              placeholder="blurred"
              layout="constrained"
              width={700}
              quality={95}
            />
          </Box>
        </VStack>
      </Box>
    )}
  </>
);

export default Banner;
