import React from "react";

import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";

type Props = {
  slides: {
    title: string;
    description: string;
    image: string;
  }[];
  isLargerThanMd: boolean;
};

type SlideProps = {
  title: string;
  description: string;
  index: number;
};

const fadeInOutVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Slide = ({ title, description, index }: SlideProps) => (
  <>
    <Box
      width={["90%", "50%"]}
      mx={["auto", "none"]}
      height="100%"
      overflow="hidden"
      rounded="xl"
    >
      {index === 0 && (
        <StaticImage
          alt={title}
          src="../images/berlin.jpg"
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          layout="fullWidth"
          aspectRatio={1}
        />
      )}
      {index === 1 && (
        <StaticImage
          alt={title}
          src="../images/freiburg.jpg"
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          layout="fullWidth"
          aspectRatio={1}
        />
      )}
      {index === 2 && (
        <StaticImage
          alt={title}
          src="../images/edinburgh.jpg"
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          layout="fullWidth"
          aspectRatio={1}
        />
      )}
      {index === 3 && (
        <StaticImage
          alt={title}
          src="../images/cesky-krumlov.jpg"
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          layout="fullWidth"
          aspectRatio={1}
        />
      )}
    </Box>

    <Text
      width={["90%", "50%"]}
      mx={["auto", "none"]}
      fontSize={["2xl", "4xl"]}
      fontWeight="light"
      lineHeight={1}
    >
      <Text as="span" fontWeight="bold">
        {title}
        {": "}
      </Text>
      {description}
    </Text>
  </>
);

const Slides = ({ slides, isLargerThanMd }: Props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const nextSlide = () => {
    if (currentSlide === slides.length - 1) {
      return setCurrentSlide(0);
    }

    return setCurrentSlide(currentSlide + 1);
  };

  const previousSlide = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(slides.length - 1);
    }

    return setCurrentSlide(currentSlide - 1);
  };

  return (
    <Box fontFamily="Geomanist" width="90%" mx="auto" my={50}>
      {slides.map(({ description, title }, index) => {
        if (index === currentSlide) {
          return (
            <motion.div
              key={title}
              initial="hidden"
              animate={currentSlide === index ? "visible" : "hidden"}
              variants={fadeInOutVariants}
              transition={{ duration: 0.5 }}
            >
              {isLargerThanMd ? (
                <HStack gap={20}>
                  <Slide
                    title={title}
                    description={description}
                    index={index}
                  />
                </HStack>
              ) : (
                <VStack>
                  <Slide
                    title={title}
                    description={description}
                    index={index}
                  />
                </VStack>
              )}
            </motion.div>
          );
        }

        return null;
      })}

      <HStack justifyContent="center" my={10}>
        <Button onClick={previousSlide}>Previous</Button>
        <Button onClick={nextSlide}>Next</Button>
      </HStack>
    </Box>
  );
};

export default Slides;
