import React from "react";

import { Box, Center, Heading, Text, useMediaQuery } from "@chakra-ui/react";

import MainLayout from "@/components/Layouts/MainLayout";
import { Meta } from "@/components/Meta";
import Slider from "@/components/Slider";
import { useSiteMetadata } from "@/hooks";
import { Banner, Features, OurMission } from "@/sections";

const slides = [
  {
    title: "Instead of Ibiza",
    description:
      "Experience the electrifying techno scene of Berlin, Germany, where pulsating beats and groundbreaking music fill underground clubs and open-air parties throughout the night.",
    image: "../images/berlin.jpg",
  },
  {
    title: "Instead of Rome",
    description:
      "Explore the eco-conscious city of Freiburg, Germany, known for its sustainable practices, from its solar-powered trams to its abundance of green spaces and organic markets.",
    image: "../images/freiburg.jpg",
  },
  {
    title: "Instead of Dublin",
    description:
      "Embark on a literary pub crawl in Edinburgh, Scotland, where you'll visit pubs frequented by renowned writers like Robert Louis Stevenson and J.K. Rowling while listening to tales of their creative inspirations.",
    image: "../images/edinburgh.jpg",
  },
  {
    title: "Instead of Paris",
    description:
      "Explore the picturesque medieval town of Český Krumlov in the Czech Republic, a UNESCO World Heritage Site renowned for its stunning castle, winding cobblestone streets, and enchanting atmosphere.",
    image: "../images/cesky-krumlov.jpg",
  },
];

export default function Index() {
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)", {
    ssr: false,
    fallback: true,
  });

  return (
    <MainLayout isLargerThanMd={isLargerThanMd}>
      <Banner isLargerThanMd={isLargerThanMd} />

      <Center as="section" h="100vh">
        <Box w={["90%", "50%"]} textAlign="center">
          <Heading fontFamily="Geomanist">
            {"Want to experience all that the world has to offer without having to plan a thing?".toUpperCase()}
          </Heading>
        </Box>
      </Center>

      <Center as="section" h="100vh">
        <Box w={["90%", "50%"]} textAlign="center">
          <Heading fontFamily="Geomanist">
            {"Looking for an adventure that won't break the bank?".toUpperCase()}
          </Heading>
        </Box>
      </Center>

      <Center as="section" h="100vh">
        <Box w={["90%", "50%"]} textAlign="center">
          <Heading fontFamily="Geomanist">
            {"Need a break from studying and exams and do not want to spend a fortune?".toUpperCase()}
          </Heading>
        </Box>
      </Center>

      <Center as="section" h="100vh">
        <Box w={["90%", "50%"]} textAlign="center">
          <Heading fontFamily="Geomanist">
            {"Want to party like a rockstar without spending a fortune?".toUpperCase()}
          </Heading>
        </Box>
      </Center>

      <OurMission />

      <Box as="section" id="app-preview">
        <Box textAlign="center" paddingY={20} width="90%" mx="auto">
          <Heading as="h2" fontFamily="Geomanist">
            With{" "}
            <Text as="span" textColor="#5dba77">
              Weeletio
            </Text>{" "}
            you can have answers to all of those questions
          </Heading>
        </Box>

        <Features />
      </Box>

      <Slider slides={slides} isLargerThanMd={isLargerThanMd} />
    </MainLayout>
  );
}

export const Head = () => {
  const { title, subtitle, keywords, image, url } = useSiteMetadata();
  return (
    <Meta
      title={title}
      description={subtitle}
      keywords={keywords}
      image={image}
      url={url}
    />
  );
};
