import { graphql, useStaticQuery } from "gatsby";

export type SiteMetadata = {
  url: string;
  title: string;
  subtitle: string;
  keywords: string;
  copyright: string;
  image: string;
  author: {
    contacts: {
      email: string;
      twitter: string;
      youtube: string;
      facebook: string;
      linkedin: string;
      instagram: string;
    };
  };
};

const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            author {
              bio
              name
              photo
              contacts {
                email
                twitter
                youtube
                facebook
                linkedin
                instagram
              }
            }
            menu {
              path
              label
            }
            url
            title
            image
            subtitle
            copyright
            keywords
          }
        }
      }
    `,
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
